import React from 'react';
import UrlHelpers from "../../../helpers/Url";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {startGeneralFeed, stopGeneralFeed, refreshGeneralFeed, stopPlayingFeedTrack, startPlayingGeneralFeedTrack, startPlayingGeneralFeedPlaylist} from "../../../actions/feed";
import {playbackStatistic} from "../../../actions/catalog";
import NProgress from "nprogress";
import * as tracksUtils from "../../../utils/tracks";

import {ReactComponent as PlayIcon} from '../../../icons/play_circle_outline.svg';
import {ReactComponent as PauseIcon} from '../../../icons/pause_circle_outline.svg';
import {randomTrackImage} from '../../../utils/images';

class GeneralFeedPlayer extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      currentTrackBuffer: null,
      nextTrackBuffer: null,
      preloadedTrackId: null,
      currentTrackSource: null,
      pausedAt: 0,
      startedAt: 0,
      playlistIndex: 0,
      trackIndex: 0,
      playlistTrackIds: {},
      playingTrack: null,
      playingPlaylist: null,
      oldPlaylist: false,
      stop: false
    };
    this.initWebAudioApi = this.initWebAudioApi.bind(this);
    this.loadTrack = this.loadTrack.bind(this);
    this.playTrack = this.playTrack.bind(this);
    this.pauseTrack = this.pauseTrack.bind(this);
    this.onTrackLoadError = this.onTrackLoadError.bind(this);
    this.getNextTrackInfo = this.getNextTrackInfo.bind(this);
    this.onTrackFinished = this.onTrackFinished.bind(this);
    this.loadFirstTrack = this.loadFirstTrack.bind(this);
    this.preloadTrackIds = this.preloadTrackIds.bind(this);
    this.feedPlaylist = this.feedPlaylist.bind(this);
    this.webApiContext = null;
  }
  componentWillUnmount() {
    this.props.stopGeneralFeed();
  }

  componentDidMount() {
    const that = this;
    setTimeout(() => {
      that.initWebAudioApi();
      that.loadFirstTrack();
    }, 10);
  }

  preloadTrackIds(generalHeaderFeed) {
    const feed = generalHeaderFeed ? generalHeaderFeed : this.props.generalHeaderFeed;

    if (!feed) {
      return
    }

    let playlistTrackIds = {};

    feed.playlists.forEach(function(p, idx) {
      let trackList = Array.from({ length: p.tracks.length }, (_, index) => index);
      
      if (p.shuffle) {
        for (let i = trackList.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [trackList[i], trackList[j]] = [trackList[j], trackList[i]];
        }

        const randomShift = Math.floor(Math.random() * trackList.length);
        for (let i = 0; i < randomShift; i++) {
            const value = trackList.pop();
            trackList.unshift(value);
        }
      }

      playlistTrackIds[idx] = trackList;
    });

    return playlistTrackIds;
  }

  loadFirstTrack(generalHeaderFeed) {
    const feed = generalHeaderFeed ? generalHeaderFeed : this.props.generalHeaderFeed;

    const playlistTrackIds = this.preloadTrackIds(feed);

    if (feed) {
      let trackInfo = this.getNextTrackInfo(0, 0, feed, playlistTrackIds);
      if (trackInfo.track) {
        this.setState({
          playlistIndex: trackInfo.playlistIndex,
          trackIndex: trackInfo.trackIndex,
          playingPlaylist: trackInfo.playlist,
          playingTrack: trackInfo.track,
          playlistTrackIds: playlistTrackIds,
          startedAt: 0,
          pausedAt: 0,
          currentTrackBuffer: null,
          nextTrackBuffer: null,
          preloadedTrackId: null,
          currentTrackSource: null,
          oldPlaylist: false
        });
        this.loadTrack(trackInfo.track.audioFile, false, false);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.generalHeaderFeed && this.props.generalHeaderFeed &&
      nextProps.generalHeaderFeed.feedId !== this.props.generalHeaderFeed.feedId ||
      !this.props.generalHeaderFeed && nextProps.generalHeaderFeed || nextProps.isGeneralHeaderFeedRefreshing
    ) {
      this.loadFirstTrack(nextProps.generalHeaderFeed);
      this.props.refreshGeneralFeed(false);
    }
  }

  // Without random track info
  // getNextTrackInfo(playlistIndex, trackIndex, feed) {
  //   let playlist = feed.playlists[playlistIndex];
  //   let track = null;

  //   if (playlist) {
  //     track = playlist.tracks[trackIndex];
  //     if (!track) {
  //       playlistIndex = playlistIndex + 1;
  //       playlist = feed.playlists[playlistIndex];
  //       if (playlist) {
  //         track = playlist.tracks[0];
  //         trackIndex = 0;
  //       }
  //     }
  //   }
  //   return {playlistIndex: playlistIndex, trackIndex: trackIndex, playlist: playlist, track: track};
  // }

  getNextTrackInfo(playlistIndex, trackIndex, feed, playlistTrackIds) {
    let track = null;

    let {playlist, finalPlaylistIndex, finalTrackIndex} = this.feedPlaylist(playlistIndex, trackIndex, feed);

    track = playlist.tracks[finalTrackIndex];

    playlistIndex = finalPlaylistIndex;
    trackIndex = finalTrackIndex;

    if (playlist.shuffle) {
      let playlistIdx = playlistTrackIds[playlistIndex];
      let trackIdx = 0;

      if (playlistIdx !== undefined) {
        trackIdx = playlistIdx[trackIndex];

        if (trackIdx === undefined) {
          playlistIndex = playlistIndex + 1;
          playlistIdx = playlistTrackIds[playlistIndex];

          if (playlistIdx === undefined) {
            playlistIndex = 0;
            playlistIdx = playlistTrackIds[playlistIndex];
          }

          if (playlistIdx !== undefined) {
            trackIdx = playlistIdx[trackIndex];
          }
        }
      } else {
        playlistIndex = 0;
      }

      playlist = feed.playlists[playlistIndex];
      track = playlist.tracks[trackIdx];
    }

    return {playlistIndex: finalPlaylistIndex, trackIndex: finalTrackIndex, playlist: playlist, track: track};
  }

  feedPlaylist(playlistIndex, trackIndex, feed) {
    let playlist = feed.playlists[playlistIndex];
    let finalTrackIndex = trackIndex;

    if (playlist) {
      if (playlist.tracks[trackIndex] === undefined) {
        playlistIndex = playlistIndex + 1;
        finalTrackIndex = 0;
        playlist = feed.playlists[playlistIndex];
        if (playlist === undefined) {
          playlistIndex = 0;
          playlist = feed.playlists[playlistIndex];
        }
      }
    }

    return {finalPlaylistIndex: playlistIndex, playlist: playlist, finalTrackIndex: finalTrackIndex};
  }

  initWebAudioApi() {
    try {
      window.AudioContext = window.AudioContext||window.webkitAudioContext;
      this.webApiContext = new AudioContext();
    }
    catch(e) {
      console.log('Web Audio API is not supported in this browser');
    }
  }

  onTrackLoadError() {
  }

  isTrackLoaded() {
    return this.state.currentTrackBuffer != null;
  }

  loadTrack(url, autoPlay, forNextTrack, trackId) {
    url = UrlHelpers.baseAudio() + '/' + url;
    let that = this;
    let request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'arraybuffer';
    request.onload = function () {
      that.webApiContext.decodeAudioData(request.response, function (buffer) {
        NProgress.done();
        if (forNextTrack) {
          that.setState({
            nextTrackBuffer: buffer,
            preloadedTrackId: trackId
          });
        } else {
          that.setState({
            currentTrackBuffer: buffer
          }, () => {
            if (autoPlay) {
              that.playTrack();
            }
          });
        }

      }, that.onTrackLoadError);
    };
    setTimeout(() => {
      NProgress.start();
      request.send();
    }, 2000);
  }

  onTrackFinished() {
    const {playlistIndex, stop, trackIndex, nextTrackBuffer, preloadedTrackId, playlistTrackIds} = this.state;

    if (stop){
      this.setState({startedAt: 0, pausedAt: 0, stop: false});
      return;
    }

    let trackInfo = this.getNextTrackInfo(playlistIndex, trackIndex + 1, this.props.generalHeaderFeed, playlistTrackIds);
    if (trackInfo.track) {
      this.setState({ startedAt: 0,
                      pausedAt: 0,
                      playlistIndex: trackInfo.playlistIndex,
                      trackIndex: trackInfo.trackIndex,
                      playingPlaylist: trackInfo.playlist,
                      oldPlaylist: (trackInfo.trackIndex > 0 && trackInfo.playlistIndex === this.state.playlistIndex),
                      playingTrack: trackInfo.track}, () => {

        if (nextTrackBuffer && preloadedTrackId !== trackInfo.track.trackId) {
          this.setState({currentTrackBuffer: nextTrackBuffer}, () => {
            this.playTrack();
          });
        } else {
          this.loadTrack(trackInfo.track.audioFile, true, false);
        }
      });
    }
  }

  playTrack() {
    if (!this.webApiContext) return;

    const {pausedAt, currentTrackBuffer, nextTrackBuffer, playlistIndex, trackIndex, preloadedTrackId, playingTrack, oldPlaylist, playingPlaylist, playlistTrackIds} = this.state;

    if (!currentTrackBuffer) return;

    //if feed playlist track playing
    tracksUtils.stopPlayer(this.props.playingFeedWavesurfer);
    this.props.stopPlayingFeedTrack();
    this.props.startPlayingGeneralFeedTrack(playingTrack);
    if (!oldPlaylist) this.props.startPlayingGeneralFeedPlaylist(playingPlaylist);

    let source = this.webApiContext.createBufferSource();
    source.buffer = currentTrackBuffer;
    source.connect(this.webApiContext.destination);
    source.onended = this.onTrackFinished;

    source.start(0, pausedAt);
    if (!nextTrackBuffer || preloadedTrackId === playingTrack.trackId || playingPlaylist.shuffle) {
      let info = this.getNextTrackInfo(playlistIndex, trackIndex + 1, this.props.generalHeaderFeed, playlistTrackIds);
      if (info.track) {
        this.loadTrack(info.track.audioFile, false, true, info.track.trackId);
        this.props.playbackStatistic(info.track.fileHash);
      }
    }
    this.setState({startedAt: this.webApiContext.currentTime - pausedAt, pausedAt: 0, currentTrackSource: source}, () => {
      this.props.startGeneralFeed();
    });
  }

  pauseTrack() {
    const {currentTrackSource} = this.state;
    currentTrackSource.disconnect();
    currentTrackSource.stop(0);
    this.setState({stop: true, pausedAt: this.webApiContext.currentTime - this.state.startedAt, currentTrackSource: null}, () => {
      this.props.stopGeneralFeed();
    });
  }

  render() {
    const {playingTrack, playingPlaylist} = this.state;
    const {generalHeaderFeed, isGeneralHeaderFeedPlaying} = this.props;
    let imageFileFix;
    if (playingTrack && playingTrack.imageFile && playingTrack.imageFile !== '') {
      imageFileFix = playingTrack.imageFile.replace(".", "_mini.");
    }

    return(
      <div className={"player " + (isGeneralHeaderFeedPlaying ? '' : "player-paused")}>
        <a className="player__image non_clickable" href="#">
          {
            (this.isTrackLoaded() && playingTrack && imageFileFix) &&
            <img src={UrlHelpers.baseAudioImg() + '/' + imageFileFix} />
          }
          {
            (!this.isTrackLoaded() || (playingTrack && !imageFileFix)) &&
            <img src={randomTrackImage['image']} />
          }
        </a>
        <div className="player__content">
          <div className="player__info player__info-feed">
            {
              !this.isTrackLoaded() &&
              <span className="player__name">
                Идет загрузка ленты...
              </span>
            }

            <div className={"player__controls " + (this.isTrackLoaded() ? '' : "hidden")}>
              {
                isGeneralHeaderFeedPlaying &&
                <PauseIcon className="player__control-icon svg-icon" onClick={this.pauseTrack} />
              }

              {
                !isGeneralHeaderFeedPlaying &&
                <PlayIcon className="player__control-icon svg-icon" onClick={this.playTrack} />
              }
            </div>

            {
              this.isTrackLoaded() && playingTrack &&
              <span className="player__name player__name-feed">
                <span><b className="player__style player__style-feed">Лента:</b>{generalHeaderFeed.name}</span><span><b className="player__style player__style-feed">Плейлист:</b>{playingPlaylist.name}</span><span><b className="player__style player__style-feed">Трек:</b>{playingTrack.title}</span>
              </span>
            }

          </div>
          <div className="player__waves">
            <div className="player__wave"></div>
            <div className="player__wave"></div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  playbackStatistic: bindActionCreators(playbackStatistic, dispatch),
  refreshGeneralFeed: bindActionCreators(refreshGeneralFeed, dispatch),
  startGeneralFeed: bindActionCreators(startGeneralFeed, dispatch),
  stopGeneralFeed: bindActionCreators(stopGeneralFeed, dispatch),
  startPlayingGeneralFeedTrack: bindActionCreators(startPlayingGeneralFeedTrack, dispatch),
  startPlayingGeneralFeedPlaylist: bindActionCreators(startPlayingGeneralFeedPlaylist, dispatch),
  stopPlayingFeedTrack: bindActionCreators(stopPlayingFeedTrack, dispatch)
});

export default connect(state => ({ isGeneralHeaderFeedPlaying: state.feed.isGeneralHeaderFeedPlaying,
    isGeneralHeaderFeedRefreshing: state.feed.isGeneralHeaderFeedRefreshing,
    playingFeedWavesurfer: state.feed.playingFeedWavesurfer}),
  mapDispatchToProps)(GeneralFeedPlayer);
